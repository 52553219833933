<template>
  <div class="card card-custom">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">
          Job Report
        </h3>
      </div>
    </div>
    <div class="card-body">
      <!--begin::Row-->
      <div class="row">
        <div class="col-xl-2"></div>
        <div class="col-xl-7">
          <!--begin::Group-->
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">Date between</label>
            <div class="col-9">
              <div class="input-group">
                <input type="date" class="form-control form-control-solid" placeholder="From" v-model="dateFrom" />
                <span class="input-group-addon">&nbsp;</span>
                <input type="date" class="form-control form-control-solid" placeholder="To" v-model="dateTo" />
              </div>
            </div>
          </div>
          <!--end::Group-->
          <!--begin::Group-->
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">Client</label>
            <div class="col-9">
              <v-select multiple v-model="client" :options="clientsOption" label="name" :reduce="client => client.id" />
              <span class="form-text text-muted">Leave blank for all clients</span>
            </div>
          </div>
          <!--end::Group-->
          <!--begin::Group-->
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">Status</label>
            <div class="col-9">
              <v-select multiple v-model="status" :options="allJobStatus" />
              <span class="form-text text-muted">Leave blank for all status</span>
            </div>
          </div>
          <!--end::Group-->
        </div>
      </div>
      <!--end::Row-->
    </div>
    <!--end::Body-->
    <!--begin::Footer-->
    <div class="card-footer">
      <div class="row">
        <div class="col-xl-2"></div>
        <div class="col-xl-7">
          <div class="row">
            <div class="col-3"></div>
            <div class="col-9">
              <a href="#" class="btn btn-light-primary font-weight-bold" @click="generate">Generate</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "@vue/composition-api";
import { listClient } from "@/api/job.api";
import { getJobReport } from "@/api/report.api";
import { getOmsStatus } from "@/utils/oms-status";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  components: { vSelect },
  setup() {
    const { allJobStatus } = getOmsStatus();

    onMounted(async () => {
      const { data } = await listClient();
      clientsOption.value = data;
    });

    const dateFrom = ref();
    const dateTo = ref();
    const status = ref([]);
    const client = ref([]);
    const clientsOption = ref([]);

    const generate = () => {
      const payload = {
        from: dateFrom.value,
        to: dateTo.value,
        status: status.value,
        client: client.value
      };

      getJobReport(payload).then(response => {
        const newBlob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        link.download = `job_report.xlsx`;
        link.click();
        setTimeout(function() {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      });
    };

    return {
      dateFrom,
      dateTo,
      client,
      status,

      clientsOption,
      allJobStatus,

      generate
    };
  }
};
</script>
